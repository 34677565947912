.form-legend {
  margin-bottom: 15px;
}

.fieldset {
  border: 0;
  padding: 0;
}

.input-file {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.input-file + label {
  background-color: rgba(249, 250, 251, 1);
  color: rgba(16, 24, 40, 1);
  cursor: pointer;
  display: inline-block;
  padding: 10px 14px;
}

.input-file + label:hover,
.input-file + label:focus {
  background-color: rgba(249, 250, 251, 1);
}


.attached-file {
  background-color: grey;
  border-radius: 8px;
  color: white;
  padding: 5px 10px;
  margin-top: 10px;
}

.badge-line {
  background-color: rgba(236, 249, 254, 1);
  color: rgba(0, 99, 134, 1);
  padding: 0.350rem;
  border-radius: 8px;
}