.input-group {
  input.step2 {
    border-right-color: transparent;

    &:focus {
      border-right-color: transparent;
    }
  }

  span.icon-step-2 {
    border-left-color: transparent !important;
    background-color: transparent !important;

    &.step-2-invalid {
      border-top-color: #dc3545;
      border-right-color: #dc3545;
      border-bottom-color: #dc3545;
    }
  }
}

.input-group {
  input.passwd {
    border-right-color: transparent;

    &:focus {
      border-right-color: transparent;
    }
  }

  span.passwd {
    border-left-color: transparent !important;
    background-color: transparent !important;
  }
}