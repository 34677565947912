@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import "mixins/banner";
@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
@import "variables-dark";
@import "maps";
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack


// Custom style
body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-variation-settings: "slnt" 0;
}

.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-centered-start {
  display: flex;
  align-items: center;
}


.nav-link {
  font-size: $font-size-sm;

  &.active-link {
    color: $primary;
    text-decoration: underline solid;
    text-underline-offset: 16px;
    font-weight: $font-weight-semibold;
  }
}


.card-body {
  & header {
    padding: 0;
  }
}

.text-normal {
  font-size: 16px;
}

.text-sm {
  font-size: 14px;
}

.text-xs {
  font-size: 12px;
}

.text-md {
  font-size: 18px;
}

.badge-verify {
  padding: 0.5rem;
  color: rgba(3, 152, 85, 1) !important;
  background-color: rgba(236, 253, 243, 1) !important;
}

.badge-warning {
  padding: 0.5rem;
  color: rgba(181, 71, 8, 1) !important;
  background-color: rgba(255, 250, 235, 1) !important;
}

.badge-process {
  padding: 0.5rem;
  color: rgba(0, 83, 112, 1) !important;
  background-color: rgba(236, 250, 255, 1) !important;
}

.badge-cancel {
  padding: 0.5rem;
  color: #f87171 !important;
  background-color: #fef2f2 !important;
}

.badge-danger {
  padding: 0.5rem;
  color: rgba(180, 35, 24, 1) !important;
  background-color: #fef2f2 !important;
}

.badge-blues {
  padding: 0.5rem;
  color: #006386 !important;
  background-color: #ECFAFF !important;
}

.img-page-forbidden {
  width: 100%;
  height: 100vh;
  object-position: bottom;
  object-fit: cover;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@include media-breakpoint-down(md) {
  .img-page-forbidden {
    height: calc(1440px /3);
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.menu-categories-approval {
  padding: 0.50rem 0;

  &.active {
    background-color: #F2F4F7;
  }
}

.btn-dashboard-secondary.active {
  background-color: #F2F4F7;
}

.btn-dashboard-secondary:hover {
  background-color: #F2F4F7;
}

.border-plat {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.nav-active {
  text-decoration: none;
  color: black;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #F2F4F7;
  padding: 8px;
  border-radius: 6px;
}

.nav-unactive {
  text-decoration: none;
  color: #667085;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 6px;
}
// Colors
$gray-color-100: #F2F4F7;
$gray-color-300: #D0D5DD;

$gray-color-600: #475467;
$gray-color-700: #344054;


$warning-color-50: #FFFAEB;


.text-gray-600 {
  color: $gray-color-600;
}
.text-gray-700 {
  color: $gray-color-700;
}

.btn-color-uang-usaha {
  border-color: $gray-color-300;
}

.bg-warning-50 {
  background-color: $warning-color-50;
}

.btn-cancel {
  border-color: $gray-color-300;
  color: $gray-color-700;
  &:hover {
    color: $gray-color-600;
    background-color: $gray-color-100;
  }
  &:disabled {
    color: $gray-color-600 !important;
    background-color: $gray-color-300 !important;
  }
}

// Colors
$gray-color-100: #F2F4F7;
$gray-color-300: #D0D5DD;

$gray-color-600: #475467;
$gray-color-700: #344054;


$warning-color-50: #FFFAEB;


.text-gray-600 {
  color: $gray-color-600;
}
.text-gray-700 {
  color: $gray-color-700;
}

.btn-color-uang-usaha {
  border-color: $gray-color-300;
}

.bg-warning-50 {
  background-color: $warning-color-50;
}

.btn-cancel {
  border-color: $gray-color-300;
  color: $gray-color-700;
  &:hover {
    color: $gray-color-600;
    background-color: $gray-color-100;
  }
}

